.card-video{
	height: 430px;
	box-shadow: 0 0 7px rgba(104,110,132,0.5);
	margin: 32px auto 0;
	max-width: 400px;
	background-color: #eceff7;
	display: block;
	position: relative;
}
.block-video__link {
  color:#fff;
  text-decoration:underline;
}
.block-video__link:hover {
  color:#ceced2;
}

.card-video__photo{
	height: 200px;
	position: relative;
	background-color: #9a7f64;
	background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.col-md-3 .card-video__photo{
	height: 140px;
}
.card-video__line{
	position: absolute;
	height: 44px;
	width: 100%;
	line-height: 44px;
	bottom: 0;
	background-color: rgba(62,62,76,0.72);
	text-align: right;
}
.card-video__play{
	position: absolute;
	width: 40px;
	height: 40px;
	top: -20px;
	left: 20px;
	display: block;
	box-shadow: 3px 0 5px rgba(46,46,46,0.46);
	border-radius: 100%;
}
.card-video__views{
	color: #fff;
	vertical-align: initial;
	font-size: 13px;
}
.card-video__views-icon{
	margin: -2px 18px 0 7px;
}
.card-video__content{
	position: relative;
	padding: 30px 40px;
}
.card-video__speaker{
	font-size: 18px;
	color: #363b42;
	font-weight: 700;
}
.card-video__name{
	font-size: 24px;
	color: #363b42;
}
.card-video__type{
	font-size: 13px;
	line-height: 24px;
	color: #8d96a3;
	text-transform: uppercase;
}
.card-video__share-container{
	position: absolute;
	right: 22px;
	bottom: 22px;
}

.card-video_size-min .card-video__line{
	height: 33px;
	line-height: 33px;
}
.card-video_size-min .card-video__play{
	width: 30px;
	height: 30px;
	top: -15px;
}
.card-video_theme-white{
	background-color: #fff;
}

@media (max-width: 1199px) and (min-width: 992px){

	.card-video__content{
		position: relative;
		padding: 20px 30px;
	}

}