.line-video-program{
	padding: 12px 0;
	display: flex;
	align-items: center;
	border-top: 1px solid #adb3bc;
	min-height: 100px;
}
.line-video-program:hover{
	cursor: pointer;
}
.line-video-program_last .line-video-program__time,
.line-video-program_last .line-video-program__name{
	color: #adb3be;
}
.line-video-program_current .line-video-program__time,
.line-video-program_current .line-video-program__name{
	color: #194cf5;
}
.line-video-program__time{
	font-size: 24px;
	color: #363b42;
	font-weight: 900;
	margin-right: 15px;
}
.line-video-program__live{
	width: 25px;
	height: 25px;
	line-height: 25px;
	font-size: 8px;
	font-weight: bold;
	border-radius: 100%;
	background-color: #f73d4f;
	color: #fff;
	text-transform: uppercase;
	margin-right: 10px;
	text-align: center;
	letter-spacing: 0.05em;
	flex-shrink: 0;
}
.line-video-program__live_disable{
	visibility: hidden;
}
.line-video-program__img{
	width: 107px;
	height: 77px;
	margin-right: 10px;
	background-size: cover;
	background-color: #ccc;
	background-position: center;
}
.line-video-program__name{
	font-size: 24px;
	color: #363b42;
}

@media (max-width: 640px){

	.line-video-program__name{
		font-size: 5vmin;
	}

}