/* плюшки и хаки */
.col-md-2_4{
	width: 20%;
	float: left;
	padding: 0 15px;
}
.no-gutters{
	margin: 0;
	padding: 0;
}
.owl-carousel .owl-item img{
	width: auto;
}
iframe[height="1px"]{
	display: none;
}
/* для выравнивания по высоте */
.line-hack-middle{
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
/* для распределения по ширине */
.line-hack-between{
	display: inline-block;
	width: 100%;
}
/* /плюшки и хаки */

/* глобально */
*{
	margin: 0;
	padding: 0;
}
body{
	font-family: 'ProximaNova', sans-serif;
	line-height: 1.2;
	font-size: 16px;
	font-weight: 400;
	color: #363b42;
}
.page-wrapper{
	max-width: 1920px;
	margin: 0 auto;
	box-shadow: 0 0 21px rgba(157,163,183,0.75);
}
/* /глобально */

/* ссылочные стили */
a[href^="tel:"]{
	white-space: nowrap;
}
.link{
	transition: all 0.25s ease;
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
.link_underline{
	text-decoration: underline;
}
.link_hover-underline:hover{
	text-decoration: underline;
}
.link:hover{
	opacity: 0.75;
}
.link:hover,
.link:visited,
.link:active,
.link:focus{
	outline: none;
	border: none;
}
.link__video{
    color:#184df7;
}
/* /ссылочные стили */

/* h1, h2 */
.header-1{
	font-size: 80px;
	line-height: 1;
	font-weight: 700;
	letter-spacing: 0.025em;
	color: #4571fd;
	text-transform: uppercase;
	position: relative;
}
.header-2{
	font-size: 40px;
	font-weight: 300;
	text-transform: uppercase;
	text-align: center;
	color: #363b42;
	vertical-align: middle;
	position: relative;
	overflow: hidden;
}
.header-2_size-min{
	font-size: 30px;
}
.header-2 br{
	display: none;
}

/* ссылка справа от заголовка блока */
.more-min{
	font-size: 13px;
	letter-spacing: 0.4em;
	text-transform: uppercase;
	position: absolute;
	right: -0.35em;
	top: 0;
	height: 100%;
	display: flex;
	align-items: center;
	font-weight: 700;
	color: #184df7;
	max-width: 200px;
	text-align: right;
}
.more-min:hover{
	text-decoration: underline;
}
/* /h1, h2 */

/* слайдер */
.block-slider__navigations{
	margin-top: 35px;
	position: relative;
}
.block-slider__nav-container{
	text-align: right;
	float: right;
	position: relative;
}
.block-slider__nav{
	display: inline-block;
	width: 43px;
	height: 40px;
	border-radius: 3px;
	border: 1px solid rgba(96,97,108,1);
	background-color: #3e3e4c;
	transition: all 0.3s ease;
	box-shadow: 0 0 7px rgba(0,0,0,0);
	cursor: pointer;
	line-height: 40px;
	text-align: center;
}
.block-slider__nav:hover{
	box-shadow: 0 0 7px rgba(0,0,0,0.28);
	background-color: #2e2e3c;
}
.block-slider__nav:after{
	display: inline-block;
	vertical-align: sub;
	width: 10px;
	height: 18px;
	content: '';
}
.block-slider__nav_next:after{
	background-image: url(/assets/layout/public/img/common/arrow-next.svg);
    margin-left: 3px;
}
.block-slider__nav_prev:after{
	background-image: url(/assets/layout/public/img/common/arrow-prev.svg);
}
.block-slider__nav_disabled{
	border: 1px solid rgba(96,97,108,0.6);
	cursor: initial;
}
.block-slider__nav_disabled:hover{
	box-shadow: 0 0 7px rgba(0,0,0,0);
	background-color: #3e3e4c;
}
.block-slider__nav_disabled:after{
	opacity: 0.5;
}
.block-slider__nav_next{
	margin-left: 14px;
}
.block-slider__dots-container{
	position: absolute;
	left: 0;
	right: 0;
	height: 40px;
	text-align: center;
	z-index: 0;
	padding-top: 13px;
}
.block-slider__dots-container .owl-dot{
	width: 14px;
    height: 14px;
    background-color: #a9afc0;
    border-radius: 100%;
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
    transition: all 0.25s ease;
}
.block-slider__dots-container .owl-dot.active{
	background-color: #3e3e4c;
}
.block-slider__dots-container .owl-dot:hover{
	opacity: 0.8;
}
/* /слайдер */

/* для всех контентных блоков страницы */
.block-page{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	background-color: #fff;
	position: relative;
	z-index: 1;
}
/* дефолтный header для некоторых страниц */
.block-page_top{
	padding: 45px 0 20px;
	background-color: #3e3e4c;
	background-image: url(/assets/layout/public/img/common/block-header-simple.jpg);
	background-position: center 50%;
}
/* /для всех контентных блоков страницы */

/* просто контейнер */
.menu-top{
	/*https://sd.synergy.ru/Task/View/320000
	background-color: #3e3e4c;
	box-shadow: 0 3px 16px rgba(0,0,0,0.2); */
	background-color: #000;
	z-index: 10;
}
/* /просто контейнер */

/* вся менюха */
.block-navigation{
	display: flex;
	justify-content: space-between;
	min-height: 50px;
	align-items: center;
	position: relative;
	z-index: 2;
}
.block-navigation__logo{
	margin-right: 15px;
	position: relative;
}
.block-navigation__link{
	display: block;
	padding: 0 8px;
	color: #ffffff;
	font-weight: 700;
	font-size: 17px;
}
.block-navigation_footer{
	height: 120px;
	border-top: 1px solid rgba(255,255,255,0.2);
	border-bottom: 1px solid rgba(255,255,255,0.2);
	margin: 16px 0;
}
.block-navigation_footer .block-navigation__link{
	color: #fff;
}
.block-navigation__search-container{
	position: relative;
	padding: 5px;
	cursor: pointer;
}
.block-navigation__search-container-img{
	box-sizing: content-box;
	position: relative;
	z-index: 2;
}
.block-navigation__search-container-input{
	position: absolute;
	right: 0px;
	width: 0px;
	padding: 0;
	height: 30px;
	top: 0px;
	transition: all 0.25s ease;
	border: none;
	outline: none;
	z-index: 1;
	opacity: 0;
} 
.block-navigation__search-container:hover .block-navigation__search-container-input,
.block-navigation__search-container-input:focus{
	width: 200px;
	padding: 0 8px;
	opacity: 1;
}
.block-navigation__phone-container{
	text-align: right;
	margin-left: 39px;
	position: relative;
}
.block-navigation__phone{
	color: #fc5969;
	font-size: 16px;
	line-height: 1;
	display: block;
}
.block-navigation__phone:last-child{
	letter-spacing: 0.01em;
}
.block-navigation_footer .block-navigation__phone{
	color: #fff;
}
/* то что учавствует в JS */
.j-block-navigation__responsive{
	display: none;
	z-index: 1;
	width: 100%;
	position: relative;
	max-height: 0;
	min-height: 0;
	overflow: hidden;
	transition: all 0.7s ease;
}
.j-block-navigation__responsive_open{
	max-height: 300px;
	min-height: 50px;
}
.j-block-navigation__responsive-icon{
	width: 30px;
	height: 28px;
	display: none;
	position: absolute;
	top: 11px;
	cursor: pointer;
}
.j-block-navigation__responsive-container{
	display: flex;
	justify-content: space-between;
	min-height: 50px;
	align-items: center;
	position: relative;
}
/* /то что учавствует в JS */
/* /вся менюха */

/* плюсик "показать еще" */
.block-pagination{
	margin: 36px auto;
	text-align: center;
	display: block;
}
.block-pagination__link{
	color: #9da3b7;
	text-transform: uppercase;
}
.block-pagination__round{
	width: 47px;
	height: 47px;
	border-radius: 50%;
	color: #eceff7;
	background-color: #9da3b7;
	font-size: 55px;
	line-height: 50px;
	margin: 0px 5px;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
}
/* /плюсик "показать еще" */

/* много страниц внутри блока */
.nav-pages{
	margin-top: 90px;
	text-align: center;
}
.nav-pages__page-link{
	color: #9da3b7;
	font-size: 18px;
	margin: 0 30px;
	line-height: 50px;
}
.nav-pages__page-link_active{
	display: inline-block;
	color: #fff;
	background-color: #9da3b7;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin: 0 10px;
}
/* если элемент одновременно восьмой и третий, он преврящается в многоточие */
.nav-pages__page-link:nth-child(8):nth-last-child(3){
	font-size: 0;
}
.nav-pages__page-link:nth-child(8):nth-last-child(3):before{
	content: "...";
	display: inline;
	font-size: 18px;
}
.nav-pages__page-link:hover{
	text-decoration: underline;
}
.nav-pages__arrow{
	cursor: pointer;
	line-height: 50px;
	font-size: 40px;
}
.nav-pages__arrow_prev{
	float: left;
}
.nav-pages__arrow_next{
	float: right;
}
/* /много страниц внутри блока */

.filter-list{
	width: 290px;
	position: relative;
	list-style: none;
	border-bottom: 1px solid rgba(255,255,255,0.4);
	padding-bottom: 3px;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	cursor: pointer;
}
.filter-list__item{
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 0.4em;
	text-transform: uppercase;
}

/* большой инпут поиска */
.block-search{
	padding: 45px 0;
	margin-top: 45px;
	background-color: #eceff7;
}
.block-search__input{
	width: 100%;
	height: 50px;
	font-size: 24px;
	border: none;
	outline: none;
	padding: 0 80px 0 20px;
	margin-right: -50px;
	display: inline-block;
}
.block-search__submit{
	background-image: url(/assets/layout/public/img/common/search.svg);
	width: 26px;
	height: 26px;
	background-size: contain;
	background-color: transparent;
	border: none;
	outline: none;
	display: inline-block;
    vertical-align: sub;
}
/* /большой инпут поиска */

@media (max-width: 640px){
	
	.block-navigation__phone-container{
		display: none;
	}
	.header-1{
		font-size: 9vmin;
	}

}
@media (max-width: 767px){
	
	.more-min{
		display: none;
	}
	.header-2{
		font-size: 7vmin;
		font-weight: 600;
	}

}
@media (max-width: 991px){

	.col-md-2_4{
		width: 100%;
	}
	.menu-top .j-block-navigation__responsive{
		position: absolute;
		max-width: 288px;
	}
	.menu-top .j-block-navigation__responsive-container{
		display: block;
		background-color: #3e3e4c;
		padding: 16px;
		box-shadow: 0 3px 16px rgba(0,0,0,0.2);
	}
	.menu-top .j-block-navigation__copy{
		margin: 10px 0;
	}

}
@media (max-width: 1199px){

	.j-block-navigation__responsive-icon{
		display: inline-block;
	}
	.menu-top .j-block-navigation__responsive{
		display: block;
	}
	.menu-top .block-navigation__logo{
		margin-left: 50px;
	}

}

