.block-footer{
	background-color: #000;
	padding-bottom: 50px;
}
.block-footer__subscribe{
	background-color: #3e3e4c;
	padding: 35px 0;
	box-shadow: 0 3px 16px rgba(0,0,0,0.34);
	text-align: center;
	color: #fff;
}
.block-footer__subscribe-title{
	font-size: 40px;
	line-height: 36px;
	font-weight: 300;
	letter-spacing: 0.015;
}
.block-footer__subscribe-box{
	font-size: 13px;
	height: 58px;
	line-height: 58px;
	margin-top: 16px;
}
.block-footer__subscribe-input{
	width: 100%;
	height: 100%;
	border: 1px solid rgba(255,255,255,0.34);
	background-color: #61616e;
	outline: none;
	padding: 0 80px 0 35px;
	display: inline-block;
	float: left;
}
.block-footer__subscribe-submit{
	margin: 0 0 0 -55px;
	width: 41px;
	height: 41px;
	display: inline-block;
	border: none;
	outline: none;
	vertical-align: middle;
	border-radius: 100%;
	box-shadow: 3px 0 5px rgba(46,46,46,0.46);
	background-color: #fff;
	background-image: url(/assets/layout/public/img/footer/subscribe-arrow.png);
}
.block-footer__social{
	margin-top: 70px;
}
.block-footer__social-title{
	font-size: 13px;
	line-height: 18px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-weight: 400;
}
.block-footer__social-links{
	min-height: 150px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.block-footer__social-link{
	width: 50px;
	height: 50px;
	margin: 16px;
	background-image: url(/assets/layout/public/img/footer/social-icons-white.png);
	text-indent: -9999px;
}
.block-footer__social-link_vk{
	background-position: -0px -0px;
}
.block-footer__social-link_twitter{
	background-position: -50px -0px;
}
.block-footer__social-link_facebook{
	background-position: -0px -50px;
}
.block-footer__social-link_youtube{
	background-position: -50px -50px;
}
.block-footer__social-link_livejournal{
	background-position: -0px -100px;
}
.block-footer__social-link_instagram{
	background-position: -50px -100px;
}
.block-footer__social-link_googleplus{
	background-position: -0px -150px;
}
.block-footer__info{
	color: rgba(255,255,255,0.2) !important;
	letter-spacing: 0.025;
	font-size: 12px;
}
.block-footer__copy{
	text-transform: uppercase;
}
.block-footer__seo-hidden{
	max-height: 0;
	min-height: 0;
	overflow: hidden;
	transition: all 0.3s ease;
}
.block-footer__seo-open{
	width: 105px;
	margin-left: -105px;
	border: none;
	outline: none;
	height: 1.2em;
	vertical-align: middle;
	opacity: 0;
	cursor: pointer;
}
.block-footer__seo-open:checked + .block-footer__seo-hidden{
	min-height: 50px;
	max-height: 999px;
}

.footer__copyright {
	margin-top: 2rem;
	font-size: 12px;
	color: rgba(255,255,255,.2) !important
}

@media (max-width: 1199px){

	.block-navigation_footer{
		display: none;
	}
	.block-footer__social-links{
		flex-wrap: wrap;
		justify-content: center;
	}

}