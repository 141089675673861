/**********/
/* Common */

@import 'common/_style';


/*************/
/* Blocks */

@import 'footer/_style';
@import 'line-video-program/_style';
@import 'webinar/_style';